import React from 'react'
import { usePageContext } from 'vike-react/usePageContext'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
// import { useLogoutFunction } from 'propelauth-vike/client'

export default function Page() {
    const { user } = usePageContext()
    // const logoutFn = useLogoutFunction()
    return (
        <Container maxWidth='lg'>
            <Box
                sx={{
                    my: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant='h4' component='h1' sx={{ mb: 2 }}>
                    Hello, {user.firstName},
                </Typography>
                {/* <button onClick={logoutFn}>Logout</button> */}
            </Box>
        </Container>
    )
}
